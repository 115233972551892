import { useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import { StripeAccountStatusDto } from '@/types/dto/stripe-account-status.dto'; // Assuming DTO exists

import { getQueryKey } from '@/api/utils/getQueryKey';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { useAuth } from '@/context/AuthProvider';

const METHOD = 'GET';
const ENDPOINT = 'connect/account-status';
export const QUERY_KEY_GET_STRIPE_ACCOUNT_STATUS = getQueryKey(METHOD, ENDPOINT);

export const useGetStripeAccountStatus = (enabled = true) => {
  const { session } = useAuth();

  return useQuery({
    enabled: enabled && !!session?.access_token,
    queryKey: QUERY_KEY_GET_STRIPE_ACCOUNT_STATUS,
    queryFn: async () => {
      try {
        // Using existing handleApiCall utility
        const data = await handleApiCall<StripeAccountStatusDto>(
          session,
          METHOD,
          getResolvedEndpoint(ENDPOINT, {}) // Pass empty object for endpoints without params
        );
        return data;
      } catch (err) {
        // Following pattern of using enqueueSnackbar for errors
        enqueueSnackbar('Failed to fetch Stripe account status.', { variant: 'error' });
        // eslint-disable-next-line no-console
        console.error(err);
        // Return null on error, consistent with some other hooks observed (though re-throwing might be better)
        return null;
      }
    },
    // Optional: Add staleTime or cacheTime if needed
    // staleTime: 5 * 60 * 1000, // 5 minutes
  });
};
