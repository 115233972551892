import { PropertyLocationDeclaredUnitsCount, PropertyLocationType } from '@/api/enums';

export const propertyLocationDeclaredUnitsCountOptions = [
  {
    label: 'From 1 to 5',
    value: PropertyLocationDeclaredUnitsCount.FROM_1_TO_5,
  },
  {
    label: 'From 6 to 25',
    value: PropertyLocationDeclaredUnitsCount.FROM_6_TO_25,
  },
  {
    label: 'From 26 to 100',
    value: PropertyLocationDeclaredUnitsCount.FROM_26_TO_100,
  },
  {
    label: 'From 101 to 500',
    value: PropertyLocationDeclaredUnitsCount.FROM_101_TO_500,
  },
  {
    label: 'Above 500',
    value: PropertyLocationDeclaredUnitsCount.ABOVE_500,
  },
];

export const propertyLocationTypeOptions = [
  {
    label: 'Single Family Home',
    value: PropertyLocationType.SINGLE_FAMILY_HOME,
  },
  {
    label: 'Townhouse',
    value: PropertyLocationType.TOWNHOUSE,
  },
  {
    label: 'Condominium',
    value: PropertyLocationType.CONDO,
  },
  {
    label: 'Multi-Unit Home / Apartment Complex',
    value: PropertyLocationType.MULTI_UNIT,
  },
  {
    label: 'Accessory Dwelling Unit (ADU)',
    value: PropertyLocationType.ACCESSORY_DWELLING_UNIT,
  },
];
