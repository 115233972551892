import { Button, Link, Stack, Typography } from '@mui/material';
import { PropsWithChildren } from 'react';

import MuiPageWrapper from '@/@mui/MuiPageWrapper';
import { AlertTriangleIcon } from '@/assets/icons/AlertTriangleIcon';
import { useAuth } from '@/context/AuthProvider';

interface Props extends PropsWithChildren {
  title?: string;
}

const ErrorStateWithMuiPageWrapper: React.FC<Props> = ({
  title = 'Something went wrong',
  children = (
    <Typography variant="body1" textAlign="center">
      It's us, not you.
      <br />
      Please try again in a couple of minutes
      <br />
      or let us know about the issue at{' '}
      <Link component="a" href="mailto:hello@terr.ai?subject=Error%20Report">
        hello@terr.ai
      </Link>
    </Typography>
  ),
}) => {
  const { signOut } = useAuth();
  return (
    <MuiPageWrapper>
      <Stack
        display="flex"
        justifyContent="center"
        alignItems="center"
        flex="1 1 100%"
        spacing={1.5}
      >
        <AlertTriangleIcon color="error" />
        <Typography variant="h6" color="error">
          {title}
        </Typography>

        {children}

        <Stack direction="row" spacing={1} mt={1}>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            onClick={() => window.location.reload()}
          >
            Refresh
          </Button>
          <Button variant="contained" color="primary" size="small" onClick={() => signOut()}>
            Log out
          </Button>
        </Stack>
      </Stack>
    </MuiPageWrapper>
  );
};

export default ErrorStateWithMuiPageWrapper;
