import { useState, useEffect, useRef } from 'react';

/**
 * Custom hook to manage a boolean state that activates on a trigger
 * and automatically resets after a specified timeout.
 *
 * @param trigger - The boolean value that activates the state.
 * @param timeoutMs - The duration in milliseconds before the state resets.
 * @returns isActive - The current state (true if trigger is active and timeout hasn't elapsed).
 */
export const useTimeoutState = (trigger: boolean, timeoutMs: number): boolean => {
  const [isActive, setIsActive] = useState(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    // Clear any existing timer if the trigger becomes false or the component unmounts
    const clearExistingTimer = () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }
    };

    if (trigger) {
      // Activate the state and set a new timer
      setIsActive(true);
      clearExistingTimer(); // Ensure no duplicate timers

      timerRef.current = setTimeout(() => {
        setIsActive(false);
        timerRef.current = null; // Clear ref after timeout completes
      }, timeoutMs);
    } else {
      // If the trigger becomes false before the timeout completes,
      // ensure the state is inactive and clear the timer.
      // Note: This part might not be strictly necessary if the trigger
      // only goes true -> false after the action completes, but it adds robustness.
      // setIsActive(false); // Optional: Reset immediately if trigger goes false
      // clearExistingTimer(); // Optional: Clear timer immediately if trigger goes false
    }

    return clearExistingTimer;
  }, [trigger, timeoutMs]);

  return isActive;
};
