import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';

import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { MutationStateFns } from '@/api/utils/mutation';
import { Id, Uuid } from '@/api/utils/sql';
import { useAuth } from '@/context/AuthProvider';

const METHOD = 'DELETE';
const ENDPOINT = 'vault/documents';

export type ApiResponse_Delete_VaultDocument = {
  id: Uuid;
};

export type ApiRequest_Delete_VaultDocument = {
  documentId: Id;
};

export const useDeleteVaultDocument = (
  stateFns?: MutationStateFns<ApiResponse_Delete_VaultDocument>
) => {
  const { session } = useAuth();

  return useMutation({
    mutationFn: async (data: ApiRequest_Delete_VaultDocument) =>
      handleApiCall<ApiResponse_Delete_VaultDocument, ApiRequest_Delete_VaultDocument>(
        session,
        METHOD,
        getResolvedEndpoint(ENDPOINT, { id: data.documentId.toString() }),
        data
      ),
    onSuccess: data => {
      enqueueSnackbar('Category of document updated successfully', { variant: 'success' });

      stateFns?.onSuccess?.(data);
    },
    onError: (err: AxiosError) => {
      enqueueSnackbar('Failed to update category of document', { variant: 'error' });

      // eslint-disable-next-line no-console
      console.error(err);
      stateFns?.onError?.(err);
    },
  });
};
