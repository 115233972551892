import { Box, SxProps } from '@mui/material';
import { LottieComponentProps, LottieRefCurrentProps } from 'lottie-react';
import React, { useRef } from 'react';

import BaseLottie from './BaseLottie';
import terriAnimation from './lotties/terri.json';

interface Props extends Partial<LottieComponentProps> {
  size?: number;
  sx?: SxProps;
}

const TerriLottie: React.FC<Props> = ({ size = 28, sx, loop = true, ...props }) => {
  const lottieRef = useRef<LottieRefCurrentProps>(null);

  const handleAnimationTrigger = () => {
    if (lottieRef.current) {
      lottieRef.current.goToAndStop(0, true);
      lottieRef.current.play();
    }
  };

  return (
    <Box
      sx={{
        height: size,
        width: size,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        ...sx,
      }}
      onMouseEnter={handleAnimationTrigger}
      onClick={handleAnimationTrigger}
    >
      <BaseLottie
        {...props}
        loop={loop}
        animationData={terriAnimation}
        ref={lottieRef}
        onLoopComplete={() => {
          if (!loop && lottieRef.current) {
            lottieRef.current.stop();
          }
        }}
      />
    </Box>
  );
};

export default TerriLottie;
