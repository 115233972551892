import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid2,
  Stack,
  Typography,
  Link,
} from '@mui/material';
import React from 'react';

import { MinusCircleIcon } from '@/assets/icons/MinusCircleIcon';
import { PlusCircleIcon } from '@/assets/icons/PlusCircleIcon';

interface FaqItem {
  question: string;
  answer: string;
}

const faq: FaqItem[] = [
  {
    question: 'Is there a free trial available?',
    answer:
      "Yes, you can try us for free for 31 days. If you want, we'll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.",
  },
  {
    question: 'Is Terr.AI free for my tenants?',
    answer:
      'Yes, Terr.AI is free for your tenants. They can continue using traditional call, text, and email.  They would pay a small transaction processing fee if they are making rental payments through our platform.',
  },
  {
    question: 'How much control do I have over AI-Powered Communications?',
    answer:
      'You have full control over the AI-Powered Communications. No response would be sent to your tenants without your approval.',
  },
  {
    question: 'Is my information secure?',
    answer:
      'Yes, your information is secure. We use industry-standard encryption to protect your data. Please refer to our Privacy Policy for more information.',
  },
  {
    question: 'Do you sell my data to third parties?',
    answer:
      'No, we do not sell your data to third parties. We only use your data to provide you with the best possible service.',
  },
  {
    question: 'How easy is offboarding?',
    answer:
      'Offboarding is easy. We always want you to be happy with our service. If you ever need to leave, we will help you with the process.',
  },

  // {
  //   question: 'Can I change my plan later?',
  //   answer: '',
  // },
  // {
  //   question: 'What is your cancellation policy?',
  //   answer: '',
  // },
  // {
  //   question: 'Can other info be added to an invoice?',
  //   answer: '',
  // },
  // {
  //   question: 'How does billing work?',
  //   answer: '',
  // },
  // {
  //   question: 'How do I change my account email?',
  //   answer: '',
  // },
];

const FaqSection: React.FC = () => (
  <Grid2 size={12}>
    <Box sx={{ py: 6, textAlign: 'center', maxWidth: 770, margin: '0 auto' }}>
      <Stack spacing={2.5} sx={{ mb: 6 }}>
        <Typography variant="h4">Frequently asked questions</Typography>

        <Typography variant="body1">
          Everything you need to know about the Terr.AI and how to join.
        </Typography>
      </Stack>

      {faq.map(item => (
        <Accordion
          key={item.question}
          sx={{
            border: 'none',
            background: 'transparent',
            pt: 2,
            pb: 3,
            '&:not(:last-child)': { borderBottom: '1px solid #E9EAEB' },
          }}
        >
          <AccordionSummary
            sx={{
              backgroundColor: 'transparent',
              flexDirection: 'row',
              '.MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                color: 'primary.main',
                transform: 'none',
              },
            }}
            expandIcon={
              <>
                <PlusCircleIcon
                  color="secondary"
                  sx={{ display: 'initial', '.Mui-expanded &': { display: 'none' } }}
                />
                <MinusCircleIcon
                  color="primary"
                  sx={{
                    display: 'none',
                    '.Mui-expanded &': { display: 'initial' },
                  }}
                />
              </>
            }
          >
            <Typography variant="h5">{item.question}</Typography>
          </AccordionSummary>

          <AccordionDetails sx={{ border: 'none', textAlign: 'left' }}>
            {item.question === 'Is my information secure?' ? (
              <Typography variant="body2">
                Yes, your information is secure. We use industry-standard encryption to protect your
                data. Please refer to our <Link href="/privacy-policy">Privacy Policy</Link> for
                more information.
              </Typography>
            ) : (
              <Typography variant="body2">{item.answer}</Typography>
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  </Grid2>
);

export default FaqSection;
