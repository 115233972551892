import { Session } from '@supabase/supabase-js';
import axios, { AxiosRequestConfig } from 'axios';
import merge from 'lodash/merge';

import { environment } from '@/environments/environment';

const getAuthenticatedAxiosConfig = (
  session: Session | null | false,
  axiosConfig?: AxiosRequestConfig
) =>
  merge(
    session
      ? {
        headers: {
          Authorization: `Bearer ${session.access_token}`,
        },
      }
      : null,
    axiosConfig
  );

const handleGetApiCall = async <ResponseData, RequestParams = never>(
  session: Session | null | false,
  endpoint: string,
  params?: RequestParams,
  axiosConfig?: AxiosRequestConfig
) => {
  const response = await axios.get<ResponseData>(
    `${environment.api}/${endpoint}`,
    merge({ params }, getAuthenticatedAxiosConfig(session, axiosConfig))
  );

  return response.data;
};

const handlePatchApiCall = async <ResponseData, RequestBody = never>(
  session: Session | null | false,
  endpoint: string,
  data?: RequestBody,
  axiosConfig?: AxiosRequestConfig
) => {
  const response = await axios.patch<ResponseData>(
    `${environment.api}/${endpoint}`,
    data,
    getAuthenticatedAxiosConfig(session, axiosConfig)
  );

  return response.data;
};

const handlePostApiCall = async <ResponseData, RequestBody = never>(
  session: Session | null | false,
  endpoint: string,
  data?: RequestBody,
  axiosConfig?: AxiosRequestConfig
) => {
  const response = await axios.post<ResponseData>(
    `${environment.api}/${endpoint}`,
    data,
    getAuthenticatedAxiosConfig(session, axiosConfig)
  );

  return response.data;
};

const handlePostFormApiCall = async <ResponseData, RequestBody = never>(
  session: Session | null | false,
  endpoint: string,
  data?: RequestBody,
  axiosConfig?: AxiosRequestConfig
) => {
  const response = await axios.postForm<ResponseData>(
    `${environment.api}/${endpoint}`,
    data,
    getAuthenticatedAxiosConfig(session, axiosConfig)
  );

  return response.data;
};

const handlePutApiCall = async <ResponseData, RequestBody = never>(
  session: Session | null | false,
  endpoint: string,
  data?: RequestBody,
  axiosConfig?: AxiosRequestConfig
) => {
  const response = await axios.put<ResponseData>(
    `${environment.api}/${endpoint}`,
    data,
    getAuthenticatedAxiosConfig(session, axiosConfig)
  );

  return response.data;
};

const handleDeleteApiCall = async <ResponseData, RequestParams = never>(
  session: Session | null | false,
  endpoint: string,
  params?: RequestParams,
  axiosConfig?: AxiosRequestConfig
) => {
  const response = await axios.delete<ResponseData>(
    `${environment.api}/${endpoint}`,
    merge({ params }, getAuthenticatedAxiosConfig(session, axiosConfig))
  );

  return response.data;
};

export const handleApiCall = async <ResponseData, RequestBody = never>(
  session: Session | null | false,
  method: 'GET' | 'PATCH' | 'POST' | 'PUT' | 'DELETE' | 'POST_FORM',
  endpoint: string,
  data?: RequestBody,
  axiosConfig?: AxiosRequestConfig
) => {
  switch (method) {
  case 'GET':
    return handleGetApiCall<ResponseData, RequestBody>(session, endpoint, data, axiosConfig);
  case 'PATCH':
    return handlePatchApiCall<ResponseData, RequestBody>(session, endpoint, data, axiosConfig);
  case 'POST':
    return handlePostApiCall<ResponseData, RequestBody>(session, endpoint, data, axiosConfig);
  case 'POST_FORM':
    return handlePostFormApiCall<ResponseData, RequestBody>(session, endpoint, data, axiosConfig);
  case 'PUT':
    return handlePutApiCall<ResponseData, RequestBody>(session, endpoint, data, axiosConfig);
  case 'DELETE':
    return handleDeleteApiCall<ResponseData, RequestBody>(session, endpoint, data, axiosConfig);
  }
};
