import { useMutation, useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import { getQueryKey } from '@/api/utils/getQueryKey';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { useAuth } from '@/context/AuthProvider';

import { QUERY_KEY_GET_STRIPE_ACCOUNT_STATUS } from './useGetStripeAccountStatus';

interface OnboardResponse {
  url: string; // Corrected property name to match backend response
}

const METHOD = 'POST';
const ENDPOINT = 'connect/onboard';
export const MUTATION_KEY_ONBOARD_STRIPE_CONNECT = getQueryKey(METHOD, ENDPOINT);

export const useOnboardStripeConnect = () => {
  const { session } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: MUTATION_KEY_ONBOARD_STRIPE_CONNECT,
    mutationFn: async () => {
      try {
        const data = await handleApiCall<OnboardResponse>(
          session,
          METHOD,
          getResolvedEndpoint(ENDPOINT, {})
        );
        return data;
      } catch (err) {
        enqueueSnackbar('Failed to start Stripe onboarding.', { variant: 'error' });
        // eslint-disable-next-line no-console
        console.error(err);
        throw err;
      }
    },
    onSuccess: data => {
      queryClient.invalidateQueries({ queryKey: QUERY_KEY_GET_STRIPE_ACCOUNT_STATUS });

      if (data?.url) {
        // Check for the correct property 'url'
        window.location.href = data.url; // Use the correct property 'url'
      } else {
        enqueueSnackbar('Failed to get Stripe onboarding link.', { variant: 'error' });
      }
    },
  });
};
