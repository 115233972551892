import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { closeSnackbar, enqueueSnackbar } from 'notistack';

import { MakePaymentFormData, Payment } from '@/types/payment';

import { getQueryKey } from '@/api/utils/getQueryKey';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { useAuth } from '@/context/AuthProvider';

import { QUERY_KEY as QUERY_KEY_GET_ALL } from './useGetPayments';

export type ApiRequest_Make_Payment = MakePaymentFormData & {
  success_url?: string;
  cancel_url?: string;
};

export type ApiResponse_Make_Payment = Payment;

interface PaymentLinkResponse {
  checkoutUrl: string;
}

const METHOD = 'POST';
const ENDPOINT = 'payments/generate-link';
export const QUERY_KEY = (params?: ApiRequest_Make_Payment) =>
  getQueryKey(METHOD, ENDPOINT, params);

export const useMakePayment = () => {
  const { session } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: MakePaymentFormData) => {
      if (!session?.access_token) {
        throw new Error('Authentication required');
      }

      // Get the current URL for success/cancel URLs
      const { origin, pathname } = window.location;
      // Use current path for both success and cancel URLs to return user to where they were
      const currentUrl = `${origin}${pathname}`;
      const successUrl = currentUrl;
      const cancelUrl = currentUrl;

      const requestData: ApiRequest_Make_Payment = {
        ...data,
        ...(data.unitId && { unit_id: data.unitId }),
        success_url: successUrl,
        cancel_url: cancelUrl,
      };

      // Generate payment link
      const response = await handleApiCall<PaymentLinkResponse, ApiRequest_Make_Payment>(
        session,
        METHOD,
        getResolvedEndpoint(ENDPOINT, {}),
        requestData
      );

      // Show countdown notification and redirect after 3 seconds
      if (response.checkoutUrl) {
        // Create a snackbar with a static message
        const snackbarKey = 'redirect-countdown';

        // Remove any existing snackbars with this key
        closeSnackbar(snackbarKey);

        // Show a single snackbar with a simplified countdown message
        enqueueSnackbar('Redirecting to payment page. Please wait...', {
          key: snackbarKey,
          variant: 'info',
          persist: true,
        });

        // Set a timeout to redirect after 3 seconds
        setTimeout(() => {
          closeSnackbar(snackbarKey);
          window.location.href = response.checkoutUrl;
        }, 3000);
      }

      // Return a dummy payment object to satisfy the type
      return {
        id: 'pending',
        amount: data.amount,
        date: new Date().toISOString(),
        status: 'pending',
        type: data.type,
        description: data.description,
      } as Payment;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: QUERY_KEY_GET_ALL(),
      });
    },
    onError: (err: AxiosError) => {
      enqueueSnackbar(
        (err as AxiosError<{ message: string }>)?.response?.data?.message ||
          'Failed to generate payment link. Please try again.',
        { variant: 'error' }
      );
    },
  });
};
