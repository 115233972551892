import { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import {
  ApiRequest_Update_PropertyLocation,
  useUpdatePropertyLocation,
} from '@/api/endpoints/propertyLocation/useUpdatePropertyLocation';
import { PropertyLocationType, PropertyLocationDeclaredUnitsCount } from '@/api/enums';
import { PropertyLocationModel } from '@/api/models/propertyLocation';

import FormAddressAutocomplete from '@/components/form/FormAddressAutocomplete';
import FormSelect from '@/components/form/FormSelect';
import FormTextField from '@/components/form/FormTextField';

import ModalWithForm from '../modals/ModalWithForm';

import { propertyLocationDeclaredUnitsCountOptions, propertyLocationTypeOptions } from './options';

interface Props {
  propertyLocation: PropertyLocationModel;
  onClose: () => void;
  onPropertyLocationUpdated?: (propertyLocation: PropertyLocationModel) => void;
}

const PropertyLocationUpdateModal: React.FC<Props> = ({
  propertyLocation,
  onClose,
  onPropertyLocationUpdated,
}) => {
  const form = useForm<ApiRequest_Update_PropertyLocation>({
    defaultValues: {
      name: propertyLocation.name,
      address_text: propertyLocation.address_text,
      type: propertyLocation.type,
      declared_units_count: propertyLocation.declared_units_count as
        | PropertyLocationDeclaredUnitsCount
        | undefined,
    },
  });

  const {
    register,
    control,
    watch,
    formState: { errors },
  } = form;

  const propertyLocationType = watch('type');

  const updatePropertyLocationMutation = useUpdatePropertyLocation(propertyLocation.id, {
    onSuccess: data => {
      onPropertyLocationUpdated?.(data);
      onClose();
    },
  });

  const onSubmit = useCallback(
    (data: ApiRequest_Update_PropertyLocation) => updatePropertyLocationMutation.mutateAsync(data),
    [updatePropertyLocationMutation]
  );

  return (
    <ModalWithForm
      onClose={onClose}
      maxWidth="xs"
      title="Edit Property Location"
      form={form}
      onSubmit={onSubmit}
    >
      <FormTextField
        label="Name"
        placeholder="Enter property name"
        error={!!errors.name}
        helperText={errors.name?.message?.toString()}
        required
        {...register('name', {
          required: 'Name is required',
        })}
        control={control}
        size="small"
      />

      <FormAddressAutocomplete
        label="Property Rental Address"
        placeholder="Enter property rental address"
        error={!!errors.address_text}
        helperText={errors.address_text?.message?.toString()}
        required
        {...register('address_text', {
          required: 'Property Rental Address is required',
        })}
        control={control}
        size="small"
      />

      <FormSelect
        label="Property Type"
        placeholder="Select property type"
        error={!!errors.type}
        helperText={errors.type?.message?.toString()}
        required
        {...register('type', {
          required: 'Property Type is required',
        })}
        control={control}
        size="small"
        options={propertyLocationTypeOptions}
      />

      {propertyLocationType === PropertyLocationType.MULTI_UNIT && (
        <FormSelect
          label="Units Count"
          placeholder="Select units count"
          error={!!errors.declared_units_count}
          helperText={errors.declared_units_count?.message?.toString()}
          required
          {...register('declared_units_count', {
            required: 'Units Count is required',
          })}
          control={control}
          size="small"
          options={propertyLocationDeclaredUnitsCountOptions}
        />
      )}
    </ModalWithForm>
  );
};

export default PropertyLocationUpdateModal;
