import Lottie, { LottieComponentProps, LottieRefCurrentProps } from 'lottie-react';
import React, { forwardRef } from 'react';

const BaseLottie = forwardRef<LottieRefCurrentProps, LottieComponentProps>(
  (
    {
      loop = true,
      autoplay = true,
      style = {
        height: '100%',
        width: '100%',
      },
      ...props
    },
    lottieRef
  ) => (
    <Lottie
      loop={loop}
      autoplay={autoplay}
      style={style}
      {...props}
      // @ts-ignore
      lottieRef={lottieRef}
    />
  )
);

export default BaseLottie;
