import React from 'react';

import BasePublicMuiPage from '../common/BasePublicMuiPage';

import FaqSection from './sections/FaqSection';
import FooterSection from './sections/FooterSection';
import HaveQuestionsSection from './sections/HaveQuestionsSection';
import LandingHeroSection from './sections/LandingHeroSection';
import NavSection from './sections/NavSection';
import ScrollIndicator from './sections/ScrollIndicator';
import WhyUsSection from './sections/WhyUsSection';

const LandingPage: React.FC = () => (
  <BasePublicMuiPage>
    <NavSection />

    <LandingHeroSection />

    <WhyUsSection />

    {/* <RentCollectionSection /> */}

    {/* <FeaturesSection /> */}

    {/* <CommunicationsSection /> */}

    <FaqSection />

    <ScrollIndicator />

    <HaveQuestionsSection />

    <FooterSection />
  </BasePublicMuiPage>
);

export default LandingPage;
