import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';

import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { MutationStateFns } from '@/api/utils/mutation';
import { useAuth } from '@/context/AuthProvider';

import { QUERY_KEY as QUERY_KEY_GET_ALL } from './useGetPayments';
import { QUERY_KEY as QUERY_KEY_GET_STRIPE_SUBSCRIPTIONS } from './useGetStripeSubscriptions';

export interface CancelStripeSubscriptionRequest {
  stripeSubscriptionId: string;
  cancelAtPeriodEnd?: boolean;
  reason?: string;
}

export interface CancelStripeSubscriptionResponse {
  success: boolean;
  message: string;
  subscriptionId: string;
}

const METHOD = 'POST';
const ENDPOINT = 'payments/subscription/cancel';

export const useCancelStripeSubscription = (
  stateFns?: MutationStateFns<CancelStripeSubscriptionResponse>
) => {
  const { session } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: CancelStripeSubscriptionRequest) => {
      if (!session?.access_token) {
        throw new Error('Authentication required');
      }

      // Map the request to match our backend API format
      const requestData = {
        subscriptionId: data.stripeSubscriptionId,
        cancelAtPeriodEnd: data.cancelAtPeriodEnd,
        reason: data.reason,
      };

      // Call the backend API to cancel the subscription
      const response = await handleApiCall<CancelStripeSubscriptionResponse, typeof requestData>(
        session,
        METHOD,
        getResolvedEndpoint(ENDPOINT, {}),
        requestData
      );

      return response;
    },
    onSuccess: data => {
      enqueueSnackbar('Subscription cancelled successfully', { variant: 'success' });

      // Invalidate both payment and subscription queries to refresh the lists
      queryClient.invalidateQueries({
        queryKey: QUERY_KEY_GET_ALL(),
      });

      queryClient.invalidateQueries({
        queryKey: QUERY_KEY_GET_STRIPE_SUBSCRIPTIONS(),
      });

      stateFns?.onSuccess?.(data);
    },
    onError: (err: AxiosError) => {
      enqueueSnackbar(
        (err as AxiosError<{ message: string }>)?.response?.data?.message ||
          'Failed to cancel subscription. Please try again.',
        { variant: 'error' }
      );

      stateFns?.onError?.(err);
    },
  });
};
