import { Box, Grid2 } from '@mui/material';
import React, { PropsWithChildren } from 'react';

import MuiPageWrapper from '@/@mui/MuiPageWrapper';

const BasePublicMuiPage: React.FC<PropsWithChildren> = ({ children }) => (
  <MuiPageWrapper>
    <Box
      sx={{
        minHeight: '100vh',
        width: '100%',
        overflowY: 'visible',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          maxWidth: 1280,
          width: '100%',
          p: 1.5,
          margin: '0 auto',
          backgroundImage: 'url(/assets/images/background-pattern.png)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '1920px 1440px',
          backgroundPosition: 'top center',
          em: {
            fontWeight: 500,
            fontStyle: 'normal',
          },
        }}
      >
        <Grid2 container spacing={2}>
          {children}
        </Grid2>
      </Box>
    </Box>
  </MuiPageWrapper>
);

export default BasePublicMuiPage;
