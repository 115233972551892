import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';

import { DocumentModel } from '@/api/models/document';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { MutationStateFns } from '@/api/utils/mutation';
import { Id } from '@/api/utils/sql';
import { useAuth } from '@/context/AuthProvider';

export type ApiResponse_UpdateCategory_Document = DocumentModel;

export type ApiRequest_UpdateCategory_Document = {
  categoryId: Id;
  id: DocumentModel['id'];
};

const METHOD = 'PATCH';
const ENDPOINT = 'document/:id/category';

export const useUpdateCategoryOfDocument = (
  stateFns?: MutationStateFns<ApiResponse_UpdateCategory_Document>
) => {
  const { session } = useAuth();

  return useMutation({
    mutationFn: async (data: ApiRequest_UpdateCategory_Document) =>
      handleApiCall<ApiResponse_UpdateCategory_Document, ApiRequest_UpdateCategory_Document>(
        session,
        METHOD,
        getResolvedEndpoint(ENDPOINT, { id: data.id.toString() }),
        data
      ),
    onSuccess: data => {
      enqueueSnackbar('Category of document updated successfully', { variant: 'success' });

      stateFns?.onSuccess?.(data);
    },
    onError: (err: AxiosError) => {
      enqueueSnackbar('Failed to update category of document', { variant: 'error' });

      // eslint-disable-next-line no-console
      console.error(err);
      stateFns?.onError?.(err);
    },
  });
};
