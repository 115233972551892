import { useMutation, useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import { TenantModelWithContacts } from '@/api/models/tenant';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { Id } from '@/api/utils/sql';
import { useAuth } from '@/context/AuthProvider';

export type ApiRequest_SetTenantAsPrimary = {
  tenantId: Id;
};

export type ApiResponse_SetTenantAsPrimary = TenantModelWithContacts;

const METHOD = 'POST';
const ENDPOINT = 'tenant/:tenantId/set-primary';

export const useSetTenantAsPrimary = () => {
  const { session } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ tenantId }: ApiRequest_SetTenantAsPrimary) => {
      try {
        const data = await handleApiCall<ApiResponse_SetTenantAsPrimary, null>(
          session,
          METHOD,
          getResolvedEndpoint(ENDPOINT, { tenantId: String(tenantId) }),
          null
        );

        return data;
      } catch (err) {
        enqueueSnackbar('Failed to set tenant as primary.', { variant: 'error' });
        throw err;
      }
    },
    onSuccess: () => {
      enqueueSnackbar('Tenant set as primary successfully.', { variant: 'success' });

      // Invalidate all tenant queries by prefix to ensure the list refreshes
      queryClient.invalidateQueries({
        predicate: query => {
          const { queryKey } = query;
          return (
            Array.isArray(queryKey) &&
            queryKey.length > 0 &&
            typeof queryKey[0] === 'string' &&
            queryKey[0].startsWith('GET_tenant')
          );
        },
      });
    },
  });
};
