import { Stack, Typography, Box, CircularProgress } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useCallback, useMemo, useState } from 'react';

import { MakePaymentFormData } from '@/types/payment';

import LoadingButton from '@/@mantis/components/@extended/LoadingButton';
import DashboardHeader from '@/@mui/dashboard/DashboardHeader';
import NoRowsOverlay from '@/@mui/data-grid/NoRowsOverlay';
import { useGetPayments } from '@/api/endpoints/payment/useGetPayments';
import { useGetStripeSubscriptions } from '@/api/endpoints/payment/useGetStripeSubscriptions';
import { useMakePayment } from '@/api/endpoints/payment/useMakePayment';
import { CreditCard01Icon } from '@/assets/icons/CreditCard01Icon';
import { useAuth } from '@/context/AuthProvider';
import { formatFullDate } from '@/utils/date';

import RentSubscriptionCard from '@/components/resident/RentSubscriptionCard';

const PaymentListPage: React.FC = () => {
  const { session } = useAuth();
  const [, setRefreshTrigger] = useState(0);

  const { data: payments = [], isLoading: isPaymentsLoading } = useGetPayments(
    {},
    !!session?.access_token
  );
  const { data: stripeSubscriptions = [], isLoading: isSubscriptionsLoading } =
    useGetStripeSubscriptions(!!session?.access_token);

  // Find the active subscription if it exists
  // Using useMemo to avoid recalculating on every render
  const activeSubscription = useMemo(() => {
    // First check in stripe subscriptions as they are more reliable/up-to-date
    const stripeSubscription = stripeSubscriptions.find(sub => sub.status === 'active');
    if (stripeSubscription) return stripeSubscription;

    // Fallback to the payment records for backward compatibility
    return payments.find(
      payment =>
        payment.status === 'active' && payment.type === 'rent' && payment.isSubscription === true
    );
  }, [payments, stripeSubscriptions]);

  // Determine if this is a Stripe subscription
  const isStripeSubscription = useMemo(() => {
    if (!activeSubscription) return false;
    return stripeSubscriptions.some(sub => sub.id === activeSubscription.id);
  }, [activeSubscription, stripeSubscriptions]);

  const isLoading = isPaymentsLoading || isSubscriptionsLoading;

  const makePaymentMutation = useMakePayment();

  const handleMakePayment = useCallback(() => {
    // Define default payment data
    const paymentData: MakePaymentFormData = {
      amount: 0, // This will be set in the Stripe checkout
      type: 'rent',
      description: 'Rent payment',
    };

    // Call the mutation to generate payment link
    makePaymentMutation.mutateAsync(paymentData);
  }, [makePaymentMutation]);

  const handleSubscriptionCancelled = useCallback(() => {
    // Trigger a refresh of the payments data
    setRefreshTrigger(prev => prev + 1);
  }, []);

  // Helper function to get color based on status
  const getStatusColor = (status: string): string => {
    if (status === 'completed' || status === 'active') {
      return 'success.main';
    }
    if (status === 'pending') {
      return 'warning.main';
    }
    return 'error.main';
  };

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'period',
        headerName: 'Rent Period',
        width: 150,
        align: 'left',
        headerAlign: 'left',
        renderCell: (params: GridRenderCellParams) => (
          <Typography
            variant="body2"
            sx={{ display: 'flex', alignItems: 'center', height: '100%' }}
          >
            {params.value}
          </Typography>
        ),
      },
      {
        field: 'amount',
        headerName: 'Amount',
        width: 120,
        align: 'left',
        headerAlign: 'left',
        renderCell: (params: GridRenderCellParams) => (
          <Typography
            variant="body2"
            sx={{ display: 'flex', alignItems: 'center', height: '100%' }}
          >
            ${params.value.toFixed(2)}
          </Typography>
        ),
      },
      {
        field: 'date',
        headerName: 'Payment Date',
        width: 180,
        align: 'left',
        headerAlign: 'left',
        renderCell: (params: GridRenderCellParams) => (
          <Typography
            variant="body2"
            sx={{ display: 'flex', alignItems: 'center', height: '100%' }}
          >
            {formatFullDate(new Date(params.value))}
          </Typography>
        ),
      },
      {
        field: 'status',
        headerName: 'Status',
        width: 120,
        align: 'left',
        headerAlign: 'left',
        renderCell: (params: GridRenderCellParams) => (
          <Typography
            variant="body2"
            sx={{
              display: 'flex',
              alignItems: 'center',
              height: '100%',
              textTransform: 'capitalize',
              color: getStatusColor(params.value as string),
            }}
          >
            {params.value}
          </Typography>
        ),
      },
      {
        field: 'description',
        headerName: 'Description',
        flex: 1,
        minWidth: 200,
        align: 'left',
        headerAlign: 'left',
        renderCell: (params: GridRenderCellParams) => (
          <Typography
            variant="body2"
            sx={{ display: 'flex', alignItems: 'center', height: '100%' }}
          >
            {params.value}
          </Typography>
        ),
      },
    ],
    []
  );

  const PaymentNoRowsOverlay = useCallback(() => <NoRowsOverlay entityName="Payment" />, []);

  if (isLoading && !payments.length && !stripeSubscriptions.length) {
    return (
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Stack direction="column" spacing={2} sx={{ flex: '1 1 100px' }}>
      <DashboardHeader hasInnerHtml title="Payments: <em>History</em>">
        <LoadingButton
          variant="contained"
          startIcon={<CreditCard01Icon />}
          onClick={handleMakePayment}
          loading={makePaymentMutation.isPending}
          disabled={!!activeSubscription}
        >
          Make Payment
        </LoadingButton>
      </DashboardHeader>

      <Typography variant="dataGridBody">
        View your payment history and manage your recurring rent ACH payment.
      </Typography>

      {activeSubscription && (
        <Box mb={2}>
          <RentSubscriptionCard
            activeSubscription={activeSubscription}
            onCancelled={handleSubscriptionCancelled}
            isStripeSubscription={isStripeSubscription}
          />
        </Box>
      )}

      <DataGrid
        rows={payments}
        columns={columns}
        density="comfortable"
        disableColumnFilter
        disableColumnMenu
        disableEval
        loading={isLoading}
        getRowHeight={() => 'auto'}
        slots={{
          noRowsOverlay: PaymentNoRowsOverlay,
        }}
        slotProps={{
          loadingOverlay: {
            variant: 'skeleton',
            noRowsVariant: 'skeleton',
          },
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: 'date', sort: 'desc' }],
          },
        }}
        disableRowSelectionOnClick
        hideFooter
      />
    </Stack>
  );
};

export default PaymentListPage;
