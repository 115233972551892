import { Grid2, Typography, Box } from '@mui/material';
import React from 'react';

import BasePublicMuiPage from '../common/BasePublicMuiPage';

import FooterSection from './sections/FooterSection';
import NavSection from './sections/NavSection';

const PrivacyPolicyPage: React.FC<{ hideNav?: boolean; hideFooter?: boolean }> = ({
  hideNav = false,
  hideFooter = false,
}) => (
  <BasePublicMuiPage>
    {!hideNav && <NavSection />}

    <Grid2 size={12}>
      <Box
        sx={{
          p: 3,
          backgroundColor: '#FFFFFF90',
          borderRadius: '10px',
          my: 3,
        }}
      >
        <Typography variant="h3" component="h1" gutterBottom>
          TERR.AI Privacy Policy
        </Typography>

        <Typography variant="body2" color="text.secondary" paragraph>
          <em>Last Updated: Aug 17 2024</em>
        </Typography>

        <Typography variant="body1" paragraph>
          This Privacy Policy describes how Terr.ai (collectively, "Terr.ai," "we," or "us")
          collects, uses, and discloses information about you. This Privacy Policy applies to
          information we collect when you access or use our website and other services
          (collectively, our "Services"), or when you otherwise interact with us online, such as
          through our customer support channels, or on social media pages. We may provide different
          or additional notices of our privacy practices with respect to certain services, in which
          case this Privacy Policy will not apply.
        </Typography>

        <Typography variant="body1" paragraph>
          We may change this Privacy Policy from time to time. If we make changes, we will notify
          you by revising the date at the top of this policy. We may also provide you with
          additional notice (for example, by adding a statement to the Services or sending you a
          notification), such as in connection with making material changes to this Privacy Policy.
          We encourage you to review this Privacy Policy regularly to stay informed about our
          information practices and the choices available to you.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
          Collection of Information
        </Typography>

        <Typography variant="h6" component="h3" gutterBottom>
          Information You Provide to Us
        </Typography>

        <Typography variant="body1" paragraph>
          We collect information you provide directly to us. For example, we collect information
          directly from you when you create an account, fill out a form, submit or post content
          through our Services, communicate with us via third-party platforms, or otherwise
          communicate with us. The specific information we collect depends on the context in which
          you provide it, and could include:
        </Typography>
        <ul>
          <li>
            <Typography variant="body1">email address;</Typography>
          </li>
          <li>
            <Typography variant="body1">postal address;</Typography>
          </li>
          <li>
            <Typography variant="body1">any other information you choose to provide.</Typography>
          </li>
        </ul>

        <Typography variant="h6" component="h3" gutterBottom>
          Information We Collect Automatically
        </Typography>

        <Typography variant="body1" paragraph>
          We automatically collect certain information about your interactions with us or our
          Services, including:
        </Typography>

        <ul>
          <li>
            <Typography variant="body1">
              Activity Information: We collect information about your activity on our Services, such
              as when you use the chat feature.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Device and Usage Information: We collect information about how you access our
              Services, including data about the device and network you use, such as your hardware
              model, operating system version, IP address, unique device identifiers, browser type,
              and app version. We also collect information about your activity on our Services, such
              as access times, pages viewed, links clicked, and the page you visited before
              navigating to our Services.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Information Collected by Cookies and Similar Tracking Technologies: We (and those who
              perform work for us) use tracking technologies, such as cookies and web beacons, to
              collect information about your interactions with the Services. Cookies are small data
              files stored on your hard drive or in device memory that help us improve our Services
              and your experience, see which areas and features of our Services are popular, and
              count visits. Web beacons (also known as "pixel tags" or "clear GIFs") are electronic
              images that we use on our Services and in our emails to help deliver cookies, count
              visits, and understand usage and campaign effectiveness. For more information about
              cookies and how to disable them, see the Your Choices section below.
            </Typography>
          </li>
        </ul>

        <Typography variant="h6" component="h3" gutterBottom>
          Information We Derive
        </Typography>

        <Typography variant="body1" paragraph>
          We may derive information or draw inferences about you based on the information we
          collect. For example, we may make inferences about your approximate location based on your
          IP address.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
          Use of Information
        </Typography>

        <Typography variant="body1" paragraph>
          We use the information we collect to provide you services and customize your experience.
          We also use the information we collect to:
        </Typography>
        <ul>
          <li>
            <Typography variant="body1">
              Provide, maintain, improve, and develop new products and services, including to debug
              and repair errors in our Services;
            </Typography>
          </li>
          <li>
            <Typography variant="body1">Personalize your experience with us;</Typography>
          </li>
          <li>
            <Typography variant="body1">
              Send you technical notices, security alerts, support messages and other transactional
              or relationship messages;
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Communicate with you about services, and events offered by Terr.ai and others and
              provide news and information that we think will interest you;
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Monitor and analyze trends, usage, and activities in connection with our products and
              services;
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Detect, investigate, and help prevent security incidents and other malicious,
              deceptive, fraudulent, or illegal activity and help protect the rights and property of
              Terr.ai and others;
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Comply with our legal and financial obligations;
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Create de-identified, anonymized or aggregated information; and
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Carry out any other purpose described to you at the time the information was
              collected.
            </Typography>
          </li>
        </ul>

        <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
          Disclousure of Information
        </Typography>

        <Typography variant="body1" paragraph>
          We share personal information in the following circumstances or as otherwise described in
          this policy:
        </Typography>
        <ul>
          <li>
            <Typography variant="body1">
              We disclose personal information with service providers that access personal
              information to perform work for us, such as companies that assist us with web hosting,
              fraud prevention, and customer service.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              We may disclose personal information if we believe that disclosure is in accordance
              with, or required by, any applicable law or legal process, including lawful requests
              by public authorities to meet national security or law enforcement requirements.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              We may disclose personal information if we believe that your actions are inconsistent
              with our user agreements or policies, if we believe that you have violated the law, or
              if we believe it is necessary to protect the rights, property, and safety of Terr.ai,
              our users, the public, or others.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              We disclose personal information to our lawyers and other professional advisors where
              necessary to obtain advice or otherwise protect and manage our business interests.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              We may disclose personal information in connection with, or during negotiations
              concerning, any merger, sale of company assets, financing, or acquisition of all or a
              portion of our business by another company.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Personal information is disclosed between and among Terr.ai and our current and future
              parents, affiliates, and subsidiaries and other companies under common control and
              ownership.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              We disclose personal information with your consent or at your direction. We also share
              aggregated or de-identified information that cannot reasonably be used to identify
              you.
            </Typography>
          </li>
        </ul>

        <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
          Analytics
        </Typography>

        <Typography variant="body1" paragraph>
          We engage others to provide analytics services on our website or relating to our Services.
          These entities may use cookies, web beacons, device identifiers, and other technologies to
          collect information about your use of our Services and other websites and applications,
          including your IP address, web browser, pages viewed, time spent on pages, links clicked,
          and conversion information. This information may be used by Terr.ai and others to, among
          other things, analyze and track data, improve our Services, determine the popularity of
          certain content, and better understand your online activity.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
          Your Choices
        </Typography>

        <Typography variant="h6" component="h3" gutterBottom>
          Account Information
        </Typography>
        <Typography variant="body1" paragraph>
          If you wish to deactivate your account, please email us at hello@terr.ai, but note that we
          may retain certain information as required by law or for our legitimate business purposes.
        </Typography>

        <Typography variant="h6" component="h3" gutterBottom>
          Cookies
        </Typography>

        <Typography variant="body1" paragraph>
          Most web browsers are set to accept cookies by default. If you prefer, you can usually
          adjust your browser settings to remove or reject browser cookies. Please note that
          removing or rejecting cookies could affect the availability and functionality of our
          Services.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
          Contact Us
        </Typography>

        <Typography variant="body1" paragraph>
          If you have any questions about this Privacy Policy, please contact us at{' '}
          <a href="mailto:hello@terr.ai">hello@terr.ai</a>.
        </Typography>
      </Box>
    </Grid2>

    {!hideFooter && <FooterSection />}
  </BasePublicMuiPage>
);

export default PrivacyPolicyPage;
