export enum DocumentStatus {
  NEW = 'NEW',
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  UPLOADED = 'UPLOADED',
}

export enum PropertyLocationType {
  SINGLE_FAMILY_HOME = 'SINGLE_FAMILY_HOME',
  TOWNHOUSE = 'TOWNHOUSE',
  CONDO = 'CONDO',
  MULTI_UNIT = 'MULTI_UNIT',
  COMMERCIAL = 'COMMERCIAL',
  ACCESSORY_DWELLING_UNIT = 'ACCESSORY_DWELLING_UNIT',
}

export enum ContactPrivacyType {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
}

export enum PropertyLocationDeclaredUnitsCount {
  FROM_1_TO_5 = 'FROM_1_TO_5',
  FROM_6_TO_25 = 'FROM_6_TO_25',
  FROM_26_TO_100 = 'FROM_26_TO_100',
  FROM_101_TO_500 = 'FROM_101_TO_500',
  ABOVE_500 = 'ABOVE_500',
}

export enum PropertyManagementRole {
  VENDOR = 'VENDOR',
  MANAGER = 'MANAGER',
  ADMIN = 'ADMIN',
}

export enum MessageStatus {
  needs_response = 'needs_response',
  waiting_for_customer = 'waiting_for_customer',
  investigating = 'investigating',
  done = 'done',
}
