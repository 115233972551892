import { Box, Button, Grid2, Stack, Typography, Chip } from '@mui/material';
import React from 'react';

import { PublicRoutes } from '@/config/routes';

import TerriLottie from '@/components/lottie/TerriLottie';

import BlurBackground from './BlurBackground';

const LandingHeroSection: React.FC = () => (
  <Grid2 size={12} sx={{ py: 1 }}>
    <Grid2 container spacing={2}>
      <Grid2
        size={{ xs: 12, md: 6 }}
        sx={{
          py: 2,
          pt: { md: 1 },
          display: 'flex',
          alignItems: 'flex-start',
          position: 'relative',
        }}
      >
        <BlurBackground
          isStrong
          sx={{
            left: { xs: '50%', md: 0 },
            top: 0,
            transform: 'translate(-50%, -50%)',
          }}
        />

        <Stack spacing={{ md: 4, xs: 2 }} sx={{ textAlign: { xs: 'center', md: 'initial' } }}>
          <Stack spacing={{ md: 2, xs: 1 }}>
            <Typography
              variant="h1"
              sx={{
                lineHeight: 1.2,
                mb: 2,
              }}
            >
              <Box component="span" sx={{ fontWeight: 700, color: 'primary.main' }}>
                AI-Powered
              </Box>{' '}
              <Box
                component="span"
                sx={{
                  fontWeight: 200,
                  fontSize: '45px',
                  display: 'block',
                  mt: 1,
                }}
              >
                <strong>Rental Property Manager</strong>
              </Box>
            </Typography>

            <Typography
              variant="body1"
              sx={{
                fontSize: '1.5rem',
                mb: 3,
                color: 'text.secondary',
                maxWidth: { md: '90%' },
              }}
            >
              Save time, reduce stress, and increase revenue with our AI-powered solution that
              handles all aspects of your rental property management.
              <br />
              <br />
              <strong>From first-time landlords to seasoned pros, we've got you covered.</strong>
            </Typography>

            <Box
              sx={{
                mb: 3,
                display: 'flex',
                flexWrap: 'wrap',
                gap: 1,
                justifyContent: { xs: 'center', md: 'flex-start' },
              }}
            >
              {[
                'Single Family Homes',
                'Townhouses',
                'Condominiums',
                'Multi-Unit Buildings',
                'Accessory Dwelling Units (ADUs)',
              ].map(property => (
                <Chip
                  key={property}
                  label={property}
                  sx={{
                    borderRadius: 1.5,
                    py: 0.5,
                    fontWeight: 500,
                    backgroundColor: 'background.secondary',
                    border: '1px solid',
                    borderColor: 'divider',
                  }}
                />
              ))}
            </Box>
          </Stack>
          <Stack
            spacing={1.5}
            direction={{ xs: 'column', sm: 'row' }}
            justifyContent={{ xs: 'center', md: 'initial' }}
          >
            <Stack spacing={1.5} alignItems={{ xs: 'center', md: 'flex-start' }}>
              <Typography variant="body1">
                <strong>Sign up in less than 5 minutes</strong>
              </Typography>
              <Button
                variant="contained"
                color="primary"
                size="large"
                sx={{
                  px: 3,
                  py: 1,
                  borderRadius: 2,
                  fontWeight: 600,
                }}
                href={PublicRoutes.signup}
              >
                Get Started
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Grid2>

      <Grid2 size={{ xs: 12, md: 4 }} sx={{ position: 'relative' }}>
        <BlurBackground
          sx={{
            right: { xs: '50%', sm: 0 },
            top: '100%',
            transform: 'translate(50%, -50%)',
          }}
        />

        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: { md: 'calc(100dvh - 200px)' },
            mb: { xs: 4, md: 0 },
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {/* <img
            src="/assets/images/undraw_house_searching.svg"
            alt="Property Management"
            style={{
              width: '100%',
              maxWidth: '500px',
              height: 'auto',
            }}
          /> */}
          <Box position="relative">
            <TerriLottie loop={false} size={128 * 4} />
            <Box
              sx={{
                position: 'absolute',
                top: '15%',
                left: { xs: '60%', md: '85%' },
                transform: 'translateY(-50%)',
                backgroundColor: 'background.paper',
                borderRadius: '30px',
                p: { xs: 2, md: 3 },
                boxShadow: theme => `0 8px 24px ${theme.palette.primary.main}20`,
                maxWidth: { xs: 200, md: 350 },
                width: { xs: '11.25rem', md: '14.375rem' },
                border: '1px solid',
                borderColor: 'primary.main',
                backdropFilter: 'blur(5px)',
                animation: 'floatCloud 3s ease-in-out infinite',
                '&:before, &:after': {
                  content: '""',
                  position: 'absolute',
                  backgroundColor: 'background.paper',
                  border: '1px solid',
                  borderColor: 'primary.main',
                  borderRadius: '50%',
                },
                '&:before': {
                  width: '25px',
                  height: '25px',
                  left: '-15px',
                  top: '50%',
                },
                '&:after': {
                  width: '15px',
                  height: '15px',
                  left: '-30px',
                  top: '55%',
                },
                '@keyframes floatCloud': {
                  '0%, 100%': {
                    transform: 'translateY(-50%)',
                  },
                  '50%': {
                    transform: 'translateY(-60%)',
                  },
                },
                zIndex: 10,
              }}
            >
              <Typography
                variant="body1"
                fontWeight={400}
                sx={{
                  color: 'text.primary',
                  lineHeight: 1.6,
                  fontSize: { xs: '0.875rem', md: 'inherit' },
                  animation: 'fadeIn 1s ease-in',
                  '@keyframes fadeIn': {
                    '0%': { opacity: 0 },
                    '100%': { opacity: 1 },
                  },
                }}
              >
                <strong style={{ fontFamily: 'Nohemi', fontSize: '1.2rem', fontWeight: 600 }}>
                  Hello, my name is Terri, your AI-Powered Property Manager!
                </strong>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Grid2>
    </Grid2>
  </Grid2>
);

export default LandingHeroSection;
