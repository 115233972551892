import { useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import { GetRentDetailsResponse } from '@/types/dto/rent-unit.dto';

import { getQueryKey } from '@/api/utils/getQueryKey';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { useAuth } from '@/context/AuthProvider';

const METHOD = 'GET';
const ENDPOINT = 'unit/:id/rent-details';

export const getQueryKeyGetRentDetails = (unitId: number | undefined) =>
  getQueryKey(METHOD, ENDPOINT, unitId ? { id: unitId } : undefined);

export const useGetRentDetails = (unitId: number | undefined, enabled = true) => {
  const { session } = useAuth();

  return useQuery({
    queryKey: getQueryKeyGetRentDetails(unitId),
    queryFn: async () => {
      if (!unitId) {
        return null;
      }
      try {
        const data = await handleApiCall<GetRentDetailsResponse | null>(
          session,
          METHOD,
          getResolvedEndpoint(ENDPOINT, { id: unitId.toString() })
        );

        if (!data) {
          return null;
        }

        // Convert amount from cents to dollars for form display
        return {
          ...data,
          amount: data.amount / 100,
        };
      } catch (err) {
        enqueueSnackbar('Failed to fetch rent details.', { variant: 'error' });
        return null;
      }
    },
    enabled: enabled && !!session && !!unitId, // Ensure unitId is valid
    // Optional: Add staleTime or cacheTime if needed
    // staleTime: 5 * 60 * 1000, // 5 minutes
  });
};
