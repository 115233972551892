export enum PublicRoutes {
  home = '/',
  aboutUs = '/about-us',
  login = '/login',
  logout = '/logout',
  privacyPolicy = '/privacy-policy',
  termsOfService = '/terms-of-service',
  signup = '/signup',
  chat = '/chat',
  verifyEmail = '/verify-email/:email',
  pricing = '/pricing',
}

export enum HOPRoutes {
  dashboard = '/dashboard',
  documentList = '/documents',
  documentView = '/documents/:id',
  propertyCreate = '/properties/add',
  propertyList = '/properties',
  propertyView = '/properties/:id',
}

export enum OnboardingRoutes {
  index = '/onboarding',
  propertyLocationCreate = '/onboarding/property-location',
  tenantUpload = '/onboarding/tenants',
  accountTypeSelect = '/onboarding/account-type',
}

export enum PMPRoutes {
  dashboard = '/property-manager',

  onboardingChecklist = '/property-manager/onboarding-checklist',

  ruleList = '/property-manager/rules',
  workflows = '/property-manager/workflows',
  workflowList = '/property-manager/operations-hub/workflows',
  calendar = '/property-manager/operations-hub/calendar',

  buildingList = '/property-manager/buildings',
  buildingView = '/property-manager/buildings/:id',
  buildingViewUnitList = '/property-manager/buildings/:id/units',
  buildingViewDocumentList = '/property-manager/buildings/:id/documents',

  documentView = '/property-manager/documents/:id',

  propertyLocationList = '/property-manager/property-locations',
  propertyLocationView = '/property-manager/property-locations/:id',
  propertyLocationViewBuildingList = '/property-manager/property-locations/:id/buildings',
  propertyLocationViewTenantList = '/property-manager/property-locations/:locationId/tenants',
  propertyLocationViewRent = '/property-manager/property-locations/:locationId/rent',
  propertyLocationViewQuestionAnswerList = '/property-manager/property-locations/:id/question-answers',
  propertyLocationManagerList = '/property-manager/property-locations/:id/managers',
  propertyLocationViewRuleList = '/property-manager/property-locations/:id/rules',
  propertyLocationViewKnowledge = '/property-manager/property-locations/:id/knowledge',
  propertyLocationPhoneCreate = '/property-manager/property-locations/:id/phone-number',
  propertyLocationEmailCreate = '/property-manager/property-locations/:id/email-address',
  unitView = '/property-manager/units/:id',
  unitViewDocumentList = '/property-manager/units/:id/documents',
  unitViewKnowledgeList = '/property-manager/units/:id/knowledge-base',
  unitViewMessageList = '/property-manager/units/:id/messages',
  unitViewMessageView = '/property-manager/units/:id/messages/:messageId',
  unitViewTenantList = '/property-manager/units/:id/tenants',
  unitViewPastTenantList = '/property-manager/units/:id/past-tenants',
  unitViewRent = '/property-manager/units/:id/rent',

  tenantView = '/property-manager/tenants/:id',
  tenantViewDocumentList = '/property-manager/tenants/:id/documents',

  inboundMessages = '/property-manager/inbound-messages',
  inboundMessagesWithTenants = '/property-manager/inbound-messages/tenants',
  inboundMessagesWithVendors = '/property-manager/inbound-messages/vendors',

  sentEmailList = '/property-manager/sent-emails',

  sentSmsList = '/property-manager/sent-sms',

  inboundCallList = '/property-manager/inbound-call',

  issueList = '/property-manager/issues',

  account = '/property-manager/account',
  accountContact = '/property-manager/account/contact',
  accountProfile = '/property-manager/account/profile',
  accountPassword = '/property-manager/account/password',
  accountSubscription = '/property-manager/account/subscription',
  accountPayouts = '/property-manager/account/payouts',
  subscription = '/property-manager/subscription',

  tenantViewSMSList = '/property-manager/tenants/:id/sms',
  ticketList = '/property-manager/tickets',

  conversationList = '/property-manager/conversations',
  conversationView = '/property-manager/conversations/:id',

  invoiceList = '/property-manager/invoices',

  vault = '/property-manager/operations-hub/vault',
}

export enum ResidentRoutes {
  dashboard = '/resident',
  payments = '/resident/payments',
  communication = '/resident/communication',
  account = '/resident/account',
  accountContact = '/resident/account/contact',
  accountProfile = '/resident/account/profile',
  accountPassword = '/resident/account/password',
}

export type Routes = PublicRoutes | HOPRoutes | PMPRoutes | OnboardingRoutes | ResidentRoutes;

// No chat
export const associaPublicRoutes: Routes[] = [
  PublicRoutes.home,
  PublicRoutes.aboutUs,
  PublicRoutes.login,
  PublicRoutes.logout,
  PublicRoutes.privacyPolicy,
  PublicRoutes.termsOfService,
  PublicRoutes.signup,
];

export const associaAuthenticatedRoutes = [
  ...associaPublicRoutes,
  HOPRoutes.documentList,
  HOPRoutes.documentView,
];

// With chat
// We need chat to be public, because
// we create the user within the flow.
export const publicRoutes = [...Object.values(PublicRoutes)];

export const anonymousUserRoutes = [...publicRoutes, HOPRoutes.dashboard];

export const authenticatedUserRoutes = [
  ...publicRoutes,
  ...Object.values(OnboardingRoutes),
  ...Object.values(HOPRoutes),
];

export const authenticatedPropertyManagerRoutes = [
  ...authenticatedUserRoutes,
  ...Object.values(PMPRoutes),
];

export const authenticatedResidentRoutes = [
  ...authenticatedUserRoutes,
  ...Object.values(ResidentRoutes),
];

export const onboardingRoutes = [...Object.values(OnboardingRoutes)];
