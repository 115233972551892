import { Box, Grid2, Stack, Typography } from '@mui/material';
import React from 'react';

import { CalendarIcon } from '@/assets/icons/CalendarIcon';
import { ChartBreakoutSquareIcon } from '@/assets/icons/ChartBreakoutSquareIcon';
import { DocumentIcon } from '@/assets/icons/DocumentIcon';
import { MessageChatCircleIcon } from '@/assets/icons/MessageChatCircleIcon';
import { ZapIcon } from '@/assets/icons/ZapIcon';

const reasons = [
  {
    Icon: MessageChatCircleIcon,
    title: 'AI-Powered Communication',
    description:
      'Our AI handles tenant inquiries 24/7, delivering human-like responses, with you in control. All of your communications are saved in one place, so you can easily refer back to them whether it is phone calls, emails, or text messages.',
    illustration: '/assets/images/undraw_chatting.svg',
    audience: 'Perfect for single-home owners who cannot be available around the clock.',
  },
  {
    Icon: ZapIcon,
    title: 'Automated Rent Collection',
    description:
      'Set it and forget it. Automate monthly rent collection, late payment reminders, and receipt generation - eliminating payment headaches for single property owners.',
    illustration: '/assets/images/undraw_online_payments.svg',
    audience: 'Ideal for single-home owners who want hassle-free income.',
  },
  {
    Icon: ChartBreakoutSquareIcon,
    title: 'From 1 unit to 10,000',
    description:
      'From big portfolios to small ones, our dashboard brings everything together — no hassle, no clutter.',
    illustration: '/assets/images/undraw_apartment.svg',
    audience: 'Perfect for property managers with multiple buildings and units.',
  },
  {
    Icon: DocumentIcon,
    title: 'Smart Lease Generation',
    description:
      'Generate state-specific lease agreements that are always up-to-date with current landlord-tenant laws. Our legally vetted templates and e-signature capability make lease execution seamless and compliant.',
    illustration: '/assets/images/undraw_contract.svg',
    audience: 'Essential for landlords who want legally sound and hassle-free lease management.',
  },
  {
    Icon: CalendarIcon,
    title: 'Intelligent Property Calendar',
    description:
      'Stay ahead with our AI-powered calendar that automatically tracks and alerts you about crucial dates. From lease renewals and warranty expirations to maintenance schedules and insurance deadlines - never miss an important property-related date again.',
    illustration: '/assets/images/undraw_calendar.svg',
    audience:
      'Perfect for property owners who want automated tracking of important dates and deadlines.',
  },
];

const WhyUsSection: React.FC = () => (
  <Grid2 size={12}>
    <Box sx={{ py: 6, textAlign: 'center' }}>
      <Stack spacing={1.25} sx={{ maxWidth: 770, margin: '0 auto', mb: { xs: 6, md: 8 } }}>
        <Stack spacing={0.75}>
          <Typography variant="overline" sx={{ fontSize: '3.5rem', fontWeight: 600 }}>
            Why Choose Us?
          </Typography>
          <Typography variant="h3">No sign-up needed for tenants.</Typography>
        </Stack>

        <Typography variant="body1">
          <strong>Tenants continue using traditional call, text, and email.</strong>
          <br />
          <br />
          Keep things simple for your tenants — each property has its own number and email, just
          like they expect. Familiar for them, effortless for you — our system sorts and prioritizes
          every interaction behind the scenes.
        </Typography>
      </Stack>

      <Box>
        {reasons.map((reason, index) => (
          <Box
            key={reason.title}
            sx={{
              py: 4,
              borderTop: '1px solid #D9D9D9',
              backgroundColor: index % 2 === 0 ? 'white' : 'rgba(245, 245, 245, 0.5)',
            }}
          >
            <Grid2 container spacing={4} alignItems="center">
              <Grid2 size={{ xs: 12, md: 6 }} order={{ xs: 1, md: index % 2 === 0 ? 1 : 2 }}>
                <Stack
                  spacing={2}
                  sx={{
                    p: 2.5,
                    alignItems: index % 2 === 0 ? 'flex-start' : 'flex-end',
                    textAlign: index % 2 === 0 ? 'left' : 'right',
                  }}
                >
                  <Box
                    sx={{
                      height: 48,
                      width: 48,
                      borderRadius: '10px',
                      border: '1px solid #D9D9D9',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mb: 1.25,
                    }}
                  >
                    <reason.Icon fontSize="small" color="primary" />
                  </Box>

                  <Typography variant="h4">{reason.title}</Typography>

                  <Typography variant="body1">{reason.description}</Typography>

                  <Typography
                    variant="caption"
                    sx={{
                      backgroundColor: 'primary.light',
                      color: 'primary.dark',
                      py: 0.5,
                      px: 1.5,
                      borderRadius: '16px',
                      display: 'inline-block',
                    }}
                  >
                    {reason.audience}
                  </Typography>
                </Stack>
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }} order={{ xs: 2, md: index % 2 === 0 ? 2 : 1 }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                  <img
                    src={reason.illustration}
                    alt={reason.title}
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                      maxHeight: '300px',
                    }}
                  />
                </Box>
              </Grid2>
            </Grid2>
          </Box>
        ))}
      </Box>
    </Box>
  </Grid2>
);

export default WhyUsSection;
