import { Button, ButtonProps, CircularProgress } from '@mui/material';
import React from 'react';

interface LoadingButtonProps extends ButtonProps {
  isLoading: boolean;
  loadingText: string;
  children: React.ReactNode;
}

/**
 * A reusable MUI Button component that displays a loading indicator
 * and custom text when in the loading state.
 */
export const LoadingButton: React.FC<LoadingButtonProps> = ({
  isLoading,
  loadingText,
  children,
  disabled,
  ...rest
}) => (
  <Button
    disabled={disabled || isLoading}
    startIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : null}
    {...rest}
  >
    {isLoading ? loadingText : children}
  </Button>
);

export default LoadingButton;
