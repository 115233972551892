import {
  Button,
  Card,
  CardContent,
  CardActions,
  Typography,
  Stack,
  Chip,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Box,
} from '@mui/material';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';

import { Payment } from '@/types/payment';

import {
  useCancelRentSubscription,
  CancelRentSubscriptionRequest,
} from '@/api/endpoints/payment/useCancelRentSubscription';
import {
  useCancelStripeSubscription,
  CancelStripeSubscriptionRequest,
} from '@/api/endpoints/payment/useCancelStripeSubscription';
import { StripeSubscription } from '@/api/endpoints/payment/useGetStripeSubscriptions';

import ModalWithForm from '@/components/modals/ModalWithForm';

interface RentSubscriptionCardProps {
  activeSubscription: Payment | StripeSubscription;
  onCancelled: () => void;
  isStripeSubscription?: boolean;
}

interface CancelSubscriptionFormData {
  cancelAtPeriodEnd: boolean;
}

const RentSubscriptionCard: FC<RentSubscriptionCardProps> = ({
  activeSubscription,
  onCancelled,
  isStripeSubscription = false,
}) => {
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const form = useForm<CancelSubscriptionFormData>({
    defaultValues: {
      cancelAtPeriodEnd: true,
    },
  });

  // Use separate mutations to avoid type errors
  const regularCancelMutation = useCancelRentSubscription({
    onSuccess: () => onCancelled(),
  });

  const stripeCancelMutation = useCancelStripeSubscription({
    onSuccess: () => onCancelled(),
  });

  const isPending = regularCancelMutation.isPending || stripeCancelMutation.isPending;

  const handleOpenDialog = () => {
    setOpenConfirmDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenConfirmDialog(false);
    // Reset form values when closing the modal
    form.reset({
      cancelAtPeriodEnd: true,
    });
  };

  const handleCancelSubscription = async (data: CancelSubscriptionFormData) => {
    if (isStripeSubscription && 'stripe_subscription_id' in activeSubscription) {
      // For Stripe subscriptions
      const stripeRequest: CancelStripeSubscriptionRequest = {
        stripeSubscriptionId: (activeSubscription as StripeSubscription).stripe_subscription_id,
        cancelAtPeriodEnd: data.cancelAtPeriodEnd,
      };
      stripeCancelMutation.mutate(stripeRequest);
    } else {
      // For regular subscriptions
      const regularRequest: CancelRentSubscriptionRequest = {
        subscriptionId: activeSubscription.id,
      };
      regularCancelMutation.mutate(regularRequest);
    }
    setOpenConfirmDialog(false);
    return true;
  };

  // Get the subscription period information
  const renderPeriodInfo = () => {
    if (isStripeSubscription && 'current_period_end' in activeSubscription) {
      const stripeSub = activeSubscription as StripeSubscription;
      const periodEndDate = new Date(stripeSub.current_period_end);

      return (
        <Typography variant="body2" color="text.secondary">
          <strong>Current Period Ends:</strong> {periodEndDate.toLocaleDateString()}
          {stripeSub.cancel_at_period_end && (
            <Chip
              label="Cancels at period end"
              color="warning"
              size="small"
              sx={{ ml: 1, fontSize: '0.7rem' }}
            />
          )}
        </Typography>
      );
    }

    if (activeSubscription.period) {
      return (
        <Typography variant="body2" color="text.secondary">
          <strong>Period:</strong> {activeSubscription.period}
        </Typography>
      );
    }

    return null;
  };

  return (
    <>
      <Card variant="outlined" sx={{ mb: 2 }}>
        <CardContent>
          <Stack direction="row" justifyContent="space-between" alignItems="center" mb={1}>
            <Typography variant="h6" component="div">
              Active Recurring Rent ACH Payment
            </Typography>
            <Chip label="Active" color="success" size="small" sx={{ textTransform: 'uppercase' }} />
          </Stack>

          <Stack spacing={1}>
            <Typography variant="body2" color="text.secondary">
              <strong>Amount:</strong> ${activeSubscription.amount.toFixed(2)}/month
            </Typography>
            {renderPeriodInfo()}
            <Typography variant="body2" color="text.secondary">
              <strong>Started:</strong> {new Date(activeSubscription.date).toLocaleDateString()}
            </Typography>
            {activeSubscription.description && (
              <Typography variant="body2" color="text.secondary">
                <strong>Description:</strong> {activeSubscription.description}
              </Typography>
            )}
            {isStripeSubscription && (
              <Typography variant="body2" color="text.secondary">
                <strong>ACH Payment ID:</strong> {activeSubscription.subscriptionId || 'N/A'}
              </Typography>
            )}
          </Stack>
        </CardContent>
        <CardActions>
          <Button size="small" color="error" onClick={handleOpenDialog} disabled={isPending}>
            Cancel Recurring Payment
          </Button>
        </CardActions>
      </Card>

      {openConfirmDialog && (
        <ModalWithForm
          form={form}
          maxWidth="sm"
          title="Cancel Recurring Payment"
          buttonColor="error"
          buttonLabel="Yes, Cancel Payment"
          onClose={handleCloseDialog}
          onSubmit={handleCancelSubscription}
        >
          <Typography>
            Are you sure you want to cancel your recurring rent ACH payment? This will stop future
            automatic rent payments. You may need to contact your property manager to set up a
            different payment method.
          </Typography>

          {isStripeSubscription && (
            <FormControl component="fieldset" sx={{ mt: 2 }}>
              <FormLabel component="legend">Cancellation Options</FormLabel>
              <RadioGroup
                value={form.watch('cancelAtPeriodEnd').toString()}
                onChange={e => form.setValue('cancelAtPeriodEnd', e.target.value === 'true')}
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label={
                    <Box>
                      <Typography variant="body2" fontWeight="medium">
                        Cancel at period end
                      </Typography>
                      <Typography variant="caption" color="text.secondary">
                        Your recurring payment will remain active until the end of the current
                        billing period
                      </Typography>
                    </Box>
                  }
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label={
                    <Box>
                      <Typography variant="body2" fontWeight="medium" color="error.main">
                        Cancel immediately
                      </Typography>
                      <Typography variant="caption" color="text.secondary">
                        Your recurring payment will be canceled right away
                      </Typography>
                    </Box>
                  }
                />
              </RadioGroup>
            </FormControl>
          )}
        </ModalWithForm>
      )}
    </>
  );
};

export default RentSubscriptionCard;
