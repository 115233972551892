import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';

import { SetRentDetailsDto } from '@/types/dto/rent-unit.dto';

import { UnitModel } from '@/api/models/unit'; // Assuming UnitModel exists
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { MutationStateFns } from '@/api/utils/mutation'; // Assuming MutationStateFns exists
import { useAuth } from '@/context/AuthProvider';

import { QUERY_KEY as GET_ONE_UNIT_QUERY_KEY } from '../unit/useGetOneUnit';

export type ApiRequest_SetRentDetails = SetRentDetailsDto;
export type ApiResponse_SetRentDetails = UnitModel; // Assuming the API returns the updated Unit

const METHOD = 'PUT';
const ENDPOINT = 'unit/:id/rent-details';

export const useSetRentDetails = (
  unitId: UnitModel['id'],
  stateFns?: MutationStateFns<ApiResponse_SetRentDetails>
) => {
  const { session } = useAuth();
  const queryClient = useQueryClient();

  return useMutation<
    ApiResponse_SetRentDetails,
    AxiosError, // Use AxiosError for error type
    ApiRequest_SetRentDetails // Data passed to mutationFn is the DTO
  >({
    mutationFn: async (
      mutationData: ApiRequest_SetRentDetails
    ) =>
      handleApiCall<ApiResponse_SetRentDetails, ApiRequest_SetRentDetails>(
        session,
        METHOD,
        getResolvedEndpoint(ENDPOINT, { id: unitId.toString() }),
        mutationData
      ),
    onSuccess: async (apiResponseData, mutationData) => {
      enqueueSnackbar('Rent details updated successfully', { variant: 'success' });

      // Invalidate the specific unit query
      queryClient.invalidateQueries({
        queryKey: GET_ONE_UNIT_QUERY_KEY(unitId),
        refetchType: 'all',
      });

      // State update is handled via callback in the parent component.
      // Potentially invalidate list queries if rent details affect them

      stateFns?.onSuccess?.(apiResponseData);
    },
    onError: (err: AxiosError) => {
      enqueueSnackbar('Failed to update rent details', { variant: 'error' });

      stateFns?.onError?.(err);
    },
  });
};
