import { RefetchOptions, useQueryClient } from '@tanstack/react-query';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { TOnboardingProgress } from '@/types/onboarding';

import {
  useGetOnboardingProgress,
  QUERY_KEY,
  ApiResponse_GetOnboardingProgress_PropertyManager,
} from '@/api/endpoints/propertyManager/useGetOnboardingProgress';
import { AccountType } from '@/api/enums/supabase';
import { PMPRoutes, publicRoutes } from '@/config/routes';
import { isAssociaSubdomain } from '@/utils/communities';
import { getCurrentOnboardingStep } from '@/utils/onboarding';
import { isSingleFamilyEquivalentType } from '@/utils/propertyLocationTypeUtils';
import { isPathPartOfRoutes } from '@/utils/router';

import ErrorStateWithMuiPageWrapper from '@/components/ErrorStateWithMuiPageWrapper';
import LoadingStateWithMuiPageWrapper from '@/components/LoadingStateWithMuiPageWrapper';

import { useAuth } from './AuthProvider';
import { usePropertyManager } from './PropertyManagerProvider';

type OnboardingProgressContextType = {
  isFetching: boolean;
  onboardingProgress: TOnboardingProgress;
  isOnboardingComplete?: boolean;
  isMenuTourGuideOpen: boolean;
  setIsMenuTourGuideOpen: (flag: boolean) => void;
  refetch: (options?: RefetchOptions) => void;
  setOnboardingProgress: (
    progress: Partial<ApiResponse_GetOnboardingProgress_PropertyManager>
  ) => void;
};

const OnboardingProgressContext = createContext<OnboardingProgressContextType | undefined>(
  undefined
);

const OnboardingProgressProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { user } = useAuth();
  const { selectedPropertyLocation } = usePropertyManager();
  const recentlyRedirectedFor = useRef<number>();
  const queryClient = useQueryClient();

  const location = useLocation();
  const history = useHistory();

  const hasAccountType = useMemo(() => !!user?.user_metadata.account_type, [user]);

  const [isMenuTourGuideOpen, setIsMenuTourGuideOpen] = useState(false);

  const isPropertyManager = useMemo(
    () => user?.user_metadata.account_type === AccountType.property_manager,
    [user]
  );

  const isHomeOwner = useMemo(
    () => user?.user_metadata.account_type === AccountType.home_owner,
    [user]
  );

  const isSingleFamilyLocation = useMemo(
    () =>
      selectedPropertyLocation
        ? isSingleFamilyEquivalentType(selectedPropertyLocation.type)
        : false,
    [selectedPropertyLocation]
  );

  const getOnboardingProgressQuery = useGetOnboardingProgress(selectedPropertyLocation?.id);

  const setOnboardingProgress = useCallback(
    (progress: Partial<ApiResponse_GetOnboardingProgress_PropertyManager>) => {
      queryClient.setQueryData(QUERY_KEY(selectedPropertyLocation?.id), {
        ...getOnboardingProgressQuery.data,
        ...progress,
      });
    },
    [getOnboardingProgressQuery, queryClient, selectedPropertyLocation?.id]
  );

  const onboardingProgress: TOnboardingProgress = useMemo(() => {
    if (hasAccountType) {
      if (isPropertyManager) {
        return {
          hasAccountType: true,
          accountType: AccountType.property_manager,
          ...getOnboardingProgressQuery.data,
          firstPropertyLocationId:
            selectedPropertyLocation?.id ??
            getOnboardingProgressQuery.data?.firstPropertyLocationId,
          hasPropertyLocation:
            !!selectedPropertyLocation?.id || getOnboardingProgressQuery.data?.hasPropertyLocation,
        };
      }

      // Handle other account types (resident and home owner)
      return {
        hasAccountType: true,
        accountType: user?.user_metadata.account_type as AccountType,
      };
    }

    return {
      hasAccountType: false,
    };
  }, [
    hasAccountType,
    isPropertyManager,
    getOnboardingProgressQuery.data,
    selectedPropertyLocation,
    user?.user_metadata.account_type,
  ]);

  const isOnboardingComplete = useMemo(
    () =>
      (hasAccountType && isHomeOwner) ||
      (hasAccountType &&
        isPropertyManager &&
        (!!selectedPropertyLocation?.id || getOnboardingProgressQuery.data?.hasPropertyLocation) &&
        ((isSingleFamilyLocation &&
          getOnboardingProgressQuery.data?.hasPhoneNumber &&
          getOnboardingProgressQuery.data?.hasEmailAddress &&
          getOnboardingProgressQuery.data?.hasTenant &&
          getOnboardingProgressQuery.data?.hasTenantsWithOnboardingEmailSent &&
          getOnboardingProgressQuery.data?.hasLeaseDocument) ||
          (!isSingleFamilyLocation &&
            getOnboardingProgressQuery.data?.hasPhoneNumber &&
            getOnboardingProgressQuery.data?.hasBuilding &&
            getOnboardingProgressQuery.data?.hasUnit &&
            getOnboardingProgressQuery.data?.hasTenant &&
            getOnboardingProgressQuery.data?.hasTenantsWithOnboardingEmailSent))),
    [
      getOnboardingProgressQuery.data,
      hasAccountType,
      isHomeOwner,
      isPropertyManager,
      selectedPropertyLocation,
      isSingleFamilyLocation,
    ]
  );

  const { isFetching, isLoading, isError, refetch } = getOnboardingProgressQuery;

  useEffect(() => {
    if (isOnboardingComplete) return;
    if (!selectedPropertyLocation) return;
    if (recentlyRedirectedFor.current === selectedPropertyLocation.id) return;
    if (isAssociaSubdomain()) return;
    if (isFetching) return;
    if (isPathPartOfRoutes(location.pathname, publicRoutes)) return;

    recentlyRedirectedFor.current = selectedPropertyLocation.id;

    return history.replace(...getCurrentOnboardingStep(onboardingProgress));
  }, [
    isFetching,
    history,
    onboardingProgress,
    location,
    selectedPropertyLocation,
    isOnboardingComplete,
  ]);

  const value = useMemo(
    () => ({
      isFetching,
      onboardingProgress,
      isOnboardingComplete,
      refetch,
      setOnboardingProgress,
      isMenuTourGuideOpen,
      setIsMenuTourGuideOpen,
    }),
    [
      isFetching,
      isOnboardingComplete,
      onboardingProgress,
      refetch,
      setOnboardingProgress,
      isMenuTourGuideOpen,
    ]
  );

  const shouldShowLoadingState = useMemo(
    () => isLoading && !isPathPartOfRoutes(location.pathname, [PMPRoutes.onboardingChecklist]),
    [isLoading, location.pathname]
  );

  const content = useMemo(() => {
    if (isError) return <ErrorStateWithMuiPageWrapper />;

    if (shouldShowLoadingState) return <LoadingStateWithMuiPageWrapper />;

    return children;
  }, [isError, shouldShowLoadingState, children]);

  return (
    <OnboardingProgressContext.Provider value={value}>{content}</OnboardingProgressContext.Provider>
  );
};

export const useOnboardingProgress = () => {
  const context = useContext(OnboardingProgressContext);

  if (context === undefined) {
    throw new Error('useOnboardingProgress must be used within an OnboardingProgressProvider');
  }

  return context;
};

export default OnboardingProgressProvider;
