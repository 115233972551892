import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';

import { getQueryKey } from '@/api/utils/getQueryKey';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { useAuth } from '@/context/AuthProvider';

export type ApiRequest_SendPaymentLinkEmail = {
  tenantId: number;
  paymentLink: string;
  tenantEmail: string;
  tenantName: string;
};

export type ApiResponse_SendPaymentLinkEmail = void;

// This endpoint is accessible to property managers
// The backend uses @UseGuards(UserTypeGuard) with @Roles('property_manager')
const METHOD = 'POST';
const ENDPOINT = 'payments/send-payment-link-email';
export const MUTATION_KEY = () => getQueryKey(METHOD, ENDPOINT, {});

export const useSendPaymentLinkEmail = (
  options?: UseMutationOptions<
    ApiResponse_SendPaymentLinkEmail,
    AxiosError,
    ApiRequest_SendPaymentLinkEmail
  >
) => {
  const { session } = useAuth();

  return useMutation<ApiResponse_SendPaymentLinkEmail, AxiosError, ApiRequest_SendPaymentLinkEmail>(
    {
      mutationKey: MUTATION_KEY(),
      mutationFn: async (data: ApiRequest_SendPaymentLinkEmail) => {
        try {
          await handleApiCall<ApiResponse_SendPaymentLinkEmail, ApiRequest_SendPaymentLinkEmail>(
            session,
            METHOD,
            getResolvedEndpoint(ENDPOINT, {}),
            data
          );
        } catch (err) {
          enqueueSnackbar('Failed to send payment link email.', { variant: 'error' });
          throw err;
        }
      },
      ...options,
    }
  );
};
