import { Box, Button, Stack, Typography } from '@mui/material';
import { FC, useState } from 'react';

import PrivacyPolicyModal from '@/components/legal/PrivacyPolicyModal';
import TermsOfServiceModal from '@/components/legal/TermsOfServiceModal';

const LegalSection: FC = () => {
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);

  return (
    <Box>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Legal Documents
      </Typography>
      <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
        Review our legal documents to understand your rights and our obligations.
      </Typography>

      <Stack spacing={2}>
        <Button
          variant="text"
          onClick={() => setIsTermsModalOpen(true)}
          sx={{
            justifyContent: 'flex-start',
            textTransform: 'none',
            color: 'primary.main',
            p: 0,
            '&:hover': {
              backgroundColor: 'transparent',
              textDecoration: 'underline',
            },
          }}
        >
          Terms and Conditions
        </Button>
        <Button
          variant="text"
          onClick={() => setIsPrivacyModalOpen(true)}
          sx={{
            justifyContent: 'flex-start',
            textTransform: 'none',
            color: 'primary.main',
            p: 0,
            '&:hover': {
              backgroundColor: 'transparent',
              textDecoration: 'underline',
            },
          }}
        >
          Privacy Policy
        </Button>
      </Stack>

      {isTermsModalOpen && <TermsOfServiceModal onClose={() => setIsTermsModalOpen(false)} />}
      {isPrivacyModalOpen && <PrivacyPolicyModal onClose={() => setIsPrivacyModalOpen(false)} />}
    </Box>
  );
};

export default LegalSection;
