import { keyframes, Stack, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';

import { ArrowDownIcon } from '@/assets/icons/ArrowDownIcon';

interface Props {
  text?: string;
  visibilityThreshold?: number;
  selector?: string;
}

const bounceAnimation = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
`;

const fadeInAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const ScrollIndicator: React.FC<Props> = ({
  text = 'Scroll For More',
  visibilityThreshold = 150,
  selector = '#root > *',
}) => {
  const [isVisible, setIsVisible] = useState(true);

  const getScrollableContainer = useCallback(() => document.querySelector(selector), [selector]);

  const handleClick = useCallback(() => {
    getScrollableContainer()?.scrollBy({
      top: window.innerHeight,
      left: 0,
      behavior: 'smooth',
    });
  }, [getScrollableContainer]);

  useEffect(() => {
    const scrollableContainer = getScrollableContainer();
    const checkScroll = () => {
      const scrollPosition = scrollableContainer?.scrollTop ?? 0;

      setIsVisible(scrollPosition <= visibilityThreshold);
    };

    // Check initial state
    checkScroll();

    // Add event listener
    scrollableContainer?.addEventListener('scroll', checkScroll, { passive: true });

    // Cleanup
    return () => {
      scrollableContainer?.removeEventListener('scroll', checkScroll);
    };
  }, [getScrollableContainer, visibilityThreshold]);

  // Don't render if not visible
  if (!isVisible) return null;

  return (
    <Stack
      component="div"
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
        left: '50%',
        bottom: '5%',
        transform: 'translate(-50%, -50%)',
        zIndex: 9999,
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        borderRadius: '20px',
        padding: '10px 20px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
        animation: `${fadeInAnimation} 0.5s ease-in-out`,
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 1)',
          boxShadow: '0 6px 16px rgba(0, 0, 0, 0.2)',
        },
      }}
      onClick={handleClick}
    >
      <Typography variant="body2" color="text.secondary" sx={{ mb: 1, fontWeight: 500 }}>
        {text}
      </Typography>
      <ArrowDownIcon
        sx={{
          color: 'primary.main',
          fontSize: '28px',
          animation: `${bounceAnimation} 2s infinite`,
        }}
      />
    </Stack>
  );
};

export default ScrollIndicator;
