import { PropertyLocationType } from '@/api/enums';

/**
 * Array of property types that are equivalent to single family home
 */
export const singleFamilyEquivalentTypes = [
  PropertyLocationType.SINGLE_FAMILY_HOME,
  PropertyLocationType.TOWNHOUSE,
  PropertyLocationType.CONDO,
  PropertyLocationType.ACCESSORY_DWELLING_UNIT,
];

/**
 * Checks if a property location type belongs to the single family home category
 * @param type The property location type to check
 * @returns true if the type belongs to the single family home category
 */
export const isSingleFamilyEquivalentType = (type: PropertyLocationType): boolean =>
  singleFamilyEquivalentTypes.includes(type);

/**
 * Gets a user-friendly display text for a property location type
 * @param type The property location type
 * @returns A user-friendly display text
 */
export const getPropertyTypeDisplayText = (type: PropertyLocationType): string => {
  if (isSingleFamilyEquivalentType(type)) {
    return 'Single Family Home';
  }

  if (type === PropertyLocationType.MULTI_UNIT) {
    return 'Multi Unit';
  }

  return type;
};
