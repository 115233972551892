import { FC } from 'react';

import PrivacyPolicyPage from '@/pages/public/PrivacyPolicyPage';

import ModalWithPreview from '../modals/ModalWithPreview';

type Props = {
  onClose: () => void;
};

const PrivacyPolicyModal: FC<Props> = ({ onClose }) => (
  <ModalWithPreview onClose={onClose} title="Privacy Policy">
    <PrivacyPolicyPage hideNav hideFooter />
  </ModalWithPreview>
);

export default PrivacyPolicyModal;
