import { useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import { Payment } from '@/types/payment';

import { getQueryKey } from '@/api/utils/getQueryKey';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { useAuth } from '@/context/AuthProvider';

export interface StripeSubscription extends Payment {
  stripe_subscription_id: string;
  current_period_start: string;
  current_period_end: string;
  cancel_at_period_end: boolean;
}

const METHOD = 'GET';
const ENDPOINT = 'payments/subscriptions';

export type ApiResponse_GetStripeSubscriptions = StripeSubscription[];

// Define a separate query key for subscriptions endpoint
export const QUERY_KEY = () => getQueryKey(METHOD, ENDPOINT, {});

export const useGetStripeSubscriptions = (enabled = true) => {
  const { session } = useAuth();

  return useQuery({
    queryKey: QUERY_KEY(),
    enabled: enabled && !!session?.access_token,
    queryFn: async () => {
      try {
        // Use our dedicated backend endpoint for subscriptions
        const data = await handleApiCall<ApiResponse_GetStripeSubscriptions>(
          session,
          METHOD,
          getResolvedEndpoint(ENDPOINT, {})
        );

        return data;
      } catch (error) {
        // If the endpoint is not found/implemented, fallback to filtering from payments
        if (error?.response?.status === 404) {
          // We can't use useGetPayments in a regular function as it's a React hook
          // Instead, make a direct API call using handleApiCall
          const paymentsResponse = await handleApiCall<Payment[]>(
            session,
            'GET',
            getResolvedEndpoint('payments', {})
          );

          // Filter for payments that have Stripe subscription data
          const stripeSubscriptions = paymentsResponse
            .filter(
              payment =>
                payment.isSubscription &&
                payment.status === 'active' &&
                payment.type === 'rent' &&
                payment.subscriptionId
            )
            .map(payment => ({
              ...payment,
              // Map to our StripeSubscription interface
              stripe_subscription_id: payment.subscriptionId || '',
              current_period_start: payment.date,
              current_period_end: payment.period || '',
              cancel_at_period_end: false,
            }));

          return stripeSubscriptions;
        }

        enqueueSnackbar('Failed to fetch Stripe subscriptions.', { variant: 'error' });
        // eslint-disable-next-line no-console
        console.error(error);
        throw error;
      }
    },
  });
};
