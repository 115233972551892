import { TextField, Stack, Box, Tab, Tabs, Typography } from '@mui/material';
import React, { useState, SyntheticEvent, useEffect, useMemo } from 'react';
import { useHistory, useLocation, Switch, Route, Redirect } from 'react-router-dom';

import DashboardHeader from '@/@mui/dashboard/DashboardHeader';
import { useAuth } from '@/context/AuthProvider';
import SubscriptionSection from '@/pages/property-manager-portal/subscription/SubscriptionSection';
import StripeConnectSettings from '@/pages/shared/StripeConnectSettings'; // Import the new component

import AccountTabPanel, { a11yProps } from './account/AccountTabPanel';
import FullNameForm from './account/FullNameForm';
import LegalSection from './account/LegalSection';
import PasswordForm from './account/PasswordForm';
import PhoneForm from './account/PhoneForm';

const AccountPage: React.FC = () => {
  const { user, isPropertyManager, isResident } = useAuth();
  const location = useLocation();
  const history = useHistory();
  const [tabValue, setTabValue] = useState(0);

  // Calculate path values with useMemo to avoid recreation on every render
  const paths = useMemo(() => {
    const contactTabPath = isResident
      ? '/resident/account/contact'
      : '/property-manager/account/contact';

    const profileTabPath = isResident
      ? '/resident/account/profile'
      : '/property-manager/account/profile';

    const passwordTabPath = isResident
      ? '/resident/account/password'
      : '/property-manager/account/password';

    const legalTabPath = isResident ? '/resident/account/legal' : '/property-manager/account/legal';

    const subscriptionTabPath = '/property-manager/account/subscription';
    const payoutsTabPath = '/property-manager/account/payouts';

    return {
      contactTabPath,
      profileTabPath,
      passwordTabPath,
      legalTabPath,
      subscriptionTabPath,
      payoutsTabPath,
      TAB_PATHS: {
        contact: contactTabPath,
        profile: profileTabPath,
        password: passwordTabPath,
        legal: legalTabPath,
        subscription: subscriptionTabPath,
        payouts: payoutsTabPath,
      },
    };
  }, [isResident]);

  const {
    contactTabPath,
    profileTabPath,
    passwordTabPath,
    legalTabPath,
    subscriptionTabPath,
    payoutsTabPath,
    TAB_PATHS,
  } = paths;

  // Define tabs configuration with useMemo to avoid recreation on every render
  const TABS = useMemo(
    () => [
      { path: TAB_PATHS.contact, index: 0, label: 'Contact details' },
      { path: TAB_PATHS.profile, index: 1, label: 'Profile' },
      { path: TAB_PATHS.password, index: 2, label: 'Password' },
      { path: TAB_PATHS.legal, index: 3, label: 'Legal' },
      {
        path: TAB_PATHS.subscription,
        index: 4,
        label: 'Subscription',
        requiresPropertyManager: true,
      },
      {
        path: TAB_PATHS.payouts,
        index: 5,
        label: 'Rent Management',
        requiresPropertyManager: true,
      },
    ],
    [
      TAB_PATHS.contact,
      TAB_PATHS.profile,
      TAB_PATHS.password,
      TAB_PATHS.legal,
      TAB_PATHS.subscription,
      TAB_PATHS.payouts,
    ]
  );

  // Set the active tab based on the current URL path
  useEffect(() => {
    const tab = TABS.find(t => t.path === location.pathname);
    if (tab) {
      setTabValue(tab.index);
    } else {
      // Redirect to contact tab if path doesn't match any tab
      history.replace(TAB_PATHS.contact);
    }
  }, [location.pathname, history, TAB_PATHS.contact, TABS]);

  // Handle tab change
  const handleTabChange = (_event: SyntheticEvent, newValue: number) => {
    const newTab = TABS.find(tab => tab.index === newValue);
    if (newTab) {
      history.push(newTab.path);
    }
  };

  return (
    <Stack>
      <DashboardHeader title="Settings" />

      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabValue} onChange={handleTabChange} aria-label="account settings tabs">
            {TABS.map(tab => {
              // Only render tab if user is property manager or tab doesn't require property manager
              if (!tab.requiresPropertyManager || isPropertyManager) {
                return <Tab key={tab.path} label={tab.label} {...a11yProps(tab.index)} />;
              }
              return null;
            })}
          </Tabs>
        </Box>

        <Switch>
          <Route path={contactTabPath}>
            <AccountTabPanel value={tabValue} index={0}>
              <Stack spacing={4}>
                <Box>
                  <Typography variant="h5" sx={{ mb: 2 }}>
                    Telephone contact details
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                    Your phone number is used for account verification and important notifications.
                    We will only call you regarding urgent matters related to your account.
                  </Typography>
                  <PhoneForm />
                </Box>

                <Box>
                  <Typography variant="h5" sx={{ mb: 2 }}>
                    Email contact details
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                    Your email address is used for account login and receiving notifications. This
                    email cannot be changed after registration.
                  </Typography>
                  <TextField
                    label="Email"
                    size="small"
                    value={user?.email ?? ''}
                    fullWidth
                    disabled
                  />
                </Box>
              </Stack>
            </AccountTabPanel>
          </Route>

          <Route path={profileTabPath}>
            <AccountTabPanel value={tabValue} index={1}>
              <Box>
                <Typography variant="h5" sx={{ mb: 2 }}>
                  Profile
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                  Manage your personal information. Your name will be displayed across the platform
                  and in notifications sent to other users.
                </Typography>
                <FullNameForm />
              </Box>
            </AccountTabPanel>
          </Route>

          <Route path={passwordTabPath}>
            <AccountTabPanel value={tabValue} index={2}>
              <Box>
                <Typography variant="h5" sx={{ mb: 2 }}>
                  Password
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                  Keep your account secure with a strong password. We recommend using a combination
                  of letters, numbers, and special characters.
                </Typography>
                <PasswordForm />
              </Box>
            </AccountTabPanel>
          </Route>

          <Route path={legalTabPath}>
            <AccountTabPanel value={tabValue} index={3}>
              <LegalSection />
            </AccountTabPanel>
          </Route>

          {isPropertyManager && (
            <Route path={subscriptionTabPath}>
              <AccountTabPanel value={tabValue} index={4}>
                <Box>
                  <Typography variant="h5" sx={{ mb: 2 }}>
                    Subscription
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                    Manage your subscription details.
                  </Typography>
                  <SubscriptionSection />
                </Box>
              </AccountTabPanel>
            </Route>
          )}

          {isPropertyManager && (
            <Route path={payoutsTabPath}>
              <AccountTabPanel value={tabValue} index={5}>
                <Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Typography variant="h5">Rent Management Settings</Typography>
                    <Box
                      component="img"
                      src="/assets/images/stripe_logo.png"
                      alt="Stripe"
                      sx={{ height: 30, ml: 2 }}
                    />
                  </Box>
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                    Connect your account to our secure Stripe payment service to receive rent
                    payments directly to your bank account. Stripe handles all payment processing
                    securely, allowing you to collect rent payments automatically from your tenants.
                  </Typography>
                  <StripeConnectSettings />
                </Box>
              </AccountTabPanel>
            </Route>
          )}

          {/* Redirect to default tab if no matching route */}
          <Redirect to={contactTabPath} />
        </Switch>
      </Box>
    </Stack>
  );
};

export default AccountPage;
